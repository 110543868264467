<template>
  <div>
    <div class="uk-text-large">Productos con información incompleta</div>

    <div v-if="!isLoading">
      <div>
        Mostrando los primeros {{ incompleteProducts.length }} de
        {{ incompleteCount }} productos que necesitan información. Al completar
        la información de los productos mostrados se cargarán nuevos pendientes.
      </div>
      <table class="uk-table uk-table-middle uk-table-hover uk-table-divider">
        <thead>
          <tr>
            <th>Información</th>
            <th>Imágen</th>
            <th>Configuración de impresión</th>
            <th>Costo (De proveedor)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in incompleteProducts" :key="product.id">
            <td width="20%">
              <div>
                <span class="uk-text-bold">{{ product.provider.name }}</span>
                -
                {{ product.provider_code }}
              </div>
              <div>{{ product.name }}</div>
            </td>
            <td width="30%">
              <img
                width="100%"
                :src="
                  product.main_image
                    ? product.main_image
                    : api + '/storefronts/' + storefrontId + '/logo'
                "
                alt
              />
            </td>
            <td width="20%">
              <!-- <div
                              v-for="printTechnique in payload.printTechniques"
                              :key="printTechnique.id"
                          >
                              <label
                                  ><input
                                      class="uk-radio"
                                      type="radio"
                                      :name="'radio-' + product.id"
                                      :value="printTechnique.slug"
                                      v-model="product.print_techniques"
                                  />
                                  {{ printTechnique.name }}</label
                              >
                          </div> -->

              <v-select
                multiple
                v-model="product.print_techniques"
                class="uk-input uk-border-rounded"
                :options="
                  printingPrintTechniques.map((printTechnique) => ({
                    value: printTechnique.slug,
                    label: printTechnique.name,
                  }))
                "
                :reduce="(option) => option.value"
              ></v-select>

              <div
                class="uk-margin-small-top"
                v-if="product.print_techniques.includes('sublimation')"
              >
                <div>Alto de logo</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.printable_height"
                />

                <div>Ancho de logo</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.printable_width"
                />
              </div>

              <div class="uk-margin-small-top">
                <div>Items a imprimir</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.printable_items"
                />
              </div>
              <div class="uk-margin-small-top">
                <div>Cantidad máxima de rostros</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.max_printable_faces"
                />
              </div>
              <div class="uk-margin-small-top">
                <div>Cantidad predeterminada de rostros</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.default_printable_faces"
                />
              </div>
              <div class="uk-margin-small-top">
                <div>Cantidad máxima de colores</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.max_printable_colors"
                />
              </div>
              <div class="uk-margin-small-top">
                <div>Cantidad predeterminada de colores</div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.default_printable_colors"
                />
              </div>
            </td>
            <td width="20%">
              <div>Costo (De proveedor)</div>
              <div>
                <input
                  class="uk-input uk-border-rounded"
                  type="text"
                  v-model="product.starting_at"
                />
              </div>
            </td>
            <td width="10%">
              <button
                :disabled="product.isLoading"
                class="uk-button uk-button-primary uk-border-rounded"
                @click="update(product, index)"
              >
                <span uk-icon="check"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="uk-text-center" key="spinner-container">
      <div uk-spinner></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const extractInfo = (products) =>
  products.map((product) => {
    const images =
      product.variations.length !== 0
        ? product.variations.map((variation) => {
            const variationImage = variation.filled_fields.find(
              (filledField) => filledField.field.slug === "image"
            );
            return {
              value: variationImage ? variationImage.value : null,
              category: "image",
            };
          })
        : product.filled_fields.filter(
            (filledField) => filledField.field.slug === "image"
          );
    const prices = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "prices"
    );
    const printTechniqueField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "print-technique"
    );
    const printTechniqueSlugs =
      printTechniqueField.length !== 0
        ? printTechniqueField[0].value
        : ["serigraphy"];

    const printableItemsField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "printable-items"
    );
    const printableItems =
      printableItemsField.length !== 0 ? printableItemsField[0].value : 1;

    const printableHeightField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "printable-height"
    );
    const printableHeight =
      printableHeightField.length !== 0 ? printableHeightField[0].value : 1;

    const printableWidthField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "printable-width"
    );
    const printableWidth =
      printableWidthField.length !== 0 ? printableWidthField[0].value : 1;

    const maxPrintableFacesField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "max-printable-faces"
    );
    const maxPrintableFaces =
      maxPrintableFacesField.length !== 0 ? maxPrintableFacesField[0].value : 1;
    const defaultPrintableFacesField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "default-printable-faces"
    );
    const defaultPrintableFaces =
      defaultPrintableFacesField.length !== 0
        ? defaultPrintableFacesField[0].value
        : 1;
    const maxPrintableColorsField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "max-printable-colors"
    );
    const maxPrintableColors =
      maxPrintableColorsField.length !== 0
        ? maxPrintableColorsField[0].value
        : 2;
    const defaultPrintableColorsField = product.filled_fields.filter(
      (filledField) => filledField.field.slug === "default-printable-colors"
    );
    const defaultPrintableColors =
      defaultPrintableColorsField.length !== 0
        ? defaultPrintableColorsField[0].value
        : 1;

    return {
      ...product,
      isLoading: false,
      main_image: images.length !== 0 ? images[0].value : null,
      starting_at:
        prices.length !== 0
          ? Math.min(...prices[0].value.map((price) => price.price))
          : null,
      print_techniques: printTechniqueSlugs,
      printable_items: printableItems,
      printable_height: printableHeight,
      printable_width: printableWidth,
      max_printable_faces: maxPrintableFaces,
      default_printable_faces: defaultPrintableFaces,
      max_printable_colors: maxPrintableColors,
      default_printable_colors: defaultPrintableColors,
    };
  });

export default {
  name: "ProductsIncompleteProducts",

  data() {
    return {
      incompleteCount: null,
      incompleteProducts: null,
      isLoading: true,
    };
  },

  mounted() {
    this.printingFetchPrintTechniques();
    this.fetchProducts();
  },

  computed: {
    ...mapGetters([
      "printingPrintTechniques",
      "printingIsLoadingPrintTechniques",
    ]),
  },

  methods: {
    ...mapActions(["printingFetchPrintTechniques"]),
    update(product, index) {
      product.isLoading = true;
      const payload = {
        print_techniques: product.print_techniques,
        printable_items: product.printable_items,
        max_printable_faces: product.max_printable_faces,
        default_printable_faces: product.default_printable_faces,
        max_printable_colors: product.max_printable_colors,
        default_printable_colors: product.default_printable_colors,
        price: product.starting_at,
      };

      if (product.print_techniques.includes("sublimation")) {
        payload["printable_height"] = product.printable_height;
        payload["printable_width"] = product.printable_width;
      }

      this.axios
        .put("/products/incomplete/" + product.id.toString(), payload)
        .then(() => {
          // Check why icon keeps spinning
          this.incompleteProducts.splice(index, 1);
          if (this.incompleteProducts.length === 0) {
            this.fetchProducts();
          }
        })
        .finally(() => {
          product.isLoading = false;
        });
    },
    fetchProducts() {
      this.isLoading = true;
      this.axios
        .get("/products/incomplete")
        .then(({ data: { incompleteCount, incompleteProducts } }) => {
          this.incompleteCount = incompleteCount;
          this.incompleteProducts = extractInfo(incompleteProducts);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
